<template>
  <div
    ref="outer"
    class="relative -ml-1 before:absolute before:inset-y-0 before:left-0 before:z-[2] before:h-full before:w-1 before:to-transparent after:absolute after:inset-y-0 after:right-0 after:z-[2] after:h-full after:w-1 after:to-transparent"
    :class="classes"
  >
    <div class="pl-1">
      <span
        ref="inner"
        class="relative inline-block space-x-4 whitespace-nowrap"
        ><span ref="text" class="inline-block"><slot /></span
        ><span v-if="shouldScroll" ref="clone"><slot /></span
        ><span v-if="shouldScroll"><slot /></span
      ></span>
    </div>
  </div>
</template>

<script setup lang="ts">
interface BaseMarqueeWrapperProps {
  delay?: number
  overflow?: boolean
  speed?: number // pixels per second
}

const props = withDefaults(defineProps<BaseMarqueeWrapperProps>(), {
  delay: 0,
  overflow: false,
  speed: 15,
})

const outer = ref<HTMLElement | null>(null)
const inner = ref<HTMLElement | null>(null)
const text = ref<HTMLElement | null>(null)
const clone = ref<HTMLElement | null>(null)

const { width: outerWidth } = useElementSize(outer)
const { width: textWidth } = useElementSize(text)
const shouldScroll = computed(() => textWidth.value > outerWidth.value)

const classes = computed(() => ({
  'before:bg-gradient-to-r after:bg-gradient-to-l':
    shouldScroll.value && !props.overflow,
  'overflow-hidden': !props.overflow,
}))

const { $anime } = useNuxtApp()

const resetAnimation = () => {
  if (inner.value) {
    $anime.remove(inner.value)
    $anime.set(inner.value.style, {
      transform: null,
    })
  }
}

const animate = () => {
  if (shouldScroll.value) {
    const deltaX = clone.value?.offsetLeft || 0

    $anime({
      targets: inner.value,
      keyframes: [
        {
          translateX: -deltaX,
          duration: props.delay,
        },
        {
          translateX: -deltaX * 2,
          duration: deltaX * (1000 / props.speed),
        },
      ],
      easing: 'linear',
      complete: () => {
        resetAnimation()
        animate()
      },
    })
  }
}

watch(shouldScroll, (newValue) => {
  if (newValue) {
    animate()
  } else {
    resetAnimation()
  }
})

onMounted(() => {
  animate()
})

onBeforeUnmount(() => {
  $anime.remove(inner.value)
})
</script>
